import { MapSourceTile } from '@omniafishing/core';
import { PointLike } from 'mapbox-gl';
import { MapRef } from 'react-map-gl';
import { MapBounds, MAX_PIN_LIMIT } from '../../../components/map/omnia_map';
import { DESKTOP_PINS_LIMIT } from '../../../components/map_default/map_default';
import { ActiveMapPageModalType } from './map_page_types';

// TODO: this should be removed 🤮
// its acting as a helper function, when these arguments can be derived at the caller and passed directly to handler function this always returns to
export const getUpdatedModalType = (shouldOpen: boolean, type: ActiveMapPageModalType) => {
  const modalTypeMap: Record<ActiveMapPageModalType, ActiveMapPageModalType | null> = {
    layers: shouldOpen ? 'layers' : null,
    legend: shouldOpen ? 'legend' : null,
    featuredReports: shouldOpen ? 'featuredReports' : null,
    waypoints: shouldOpen ? 'waypoints' : null,
    lakes: shouldOpen ? 'lakes' : null,
    base: shouldOpen ? 'base' : null,
    weather: shouldOpen ? 'weather' : null,
    points_of_interest: shouldOpen ? 'points_of_interest' : null,
    core: shouldOpen ? 'core' : null,
    my_lakes: shouldOpen ? 'my_lakes' : null,
  };
  return modalTypeMap[type];
};

export const areBoundsZero = (bounds: MapBounds) => {
  return bounds.e === 0 && bounds.w === 0 && bounds.n === 0 && bounds.s === 0;
};

// "project" north western bounds to a relative pixel canvas coordinate, adjust, and "unproject" back to lat/lng
export const adjustNorthwestBounds = (map: mapboxgl.Map, bounds: MapBounds, modalWidth: number) => {
  const northwestPixel = map.project([bounds.w, bounds.n]);
  const adjustedNorthwestPixel: PointLike = [northwestPixel.x + modalWidth, northwestPixel.y];
  return map.unproject(adjustedNorthwestPixel);
};

interface Point {
  lat: number;
  lng: number;
}

interface AdjustLatLngParams {
  map: mapboxgl.Map;
  point: Point;
  xAdjust?: number;
  yAdjust?: number;
}

export const adjustLatLng = (args: AdjustLatLngParams) => {
  const { map, point, xAdjust = 0, yAdjust = 0 } = args;
  const pixel = map.project([point.lng, point.lat]);
  const adjustedPixel: PointLike = [pixel.x + xAdjust, pixel.y + yAdjust];
  return map.unproject(adjustedPixel);
};

const MAP_MODAL_WIDTH = 387;
export const getOpenModalBounds = (mapRef: MapRef, bounds: MapBounds) => {
  if (mapRef) {
    const map = mapRef.getMap();
    if (areBoundsZero(bounds)) {
      return bounds;
    }

    const adjustedNorthwest = adjustNorthwestBounds(map, bounds, MAP_MODAL_WIDTH);

    return {
      ...bounds,
      w: adjustedNorthwest.lng,
    };
  }
};

export const getClosedModalBounds = (mapRef: MapRef, bounds: MapBounds) => {
  if (mapRef) {
    const map = mapRef.getMap();
    if (areBoundsZero(bounds)) {
      return bounds;
    }

    const adjustedNorthwest = adjustNorthwestBounds(map, bounds, -MAP_MODAL_WIDTH);

    return {
      ...bounds,
      w: adjustedNorthwest.lng,
    };
  }
};

export const TEMP_TILE_MINIMUM_COVERAGE_PERCENT = 0.2;
export const TEMP_TILE_MINIMUM_COVERAGE_KM = 10;
export const TEMP_TILE_SELECTED_COVERAGE_PERCENT = 0.35;
export const MINIMUM_ZOOM = 6;

export const tileMeetsMinimumCoverage = (tile: MapSourceTile) =>
  tile.coverage_pct >= TEMP_TILE_MINIMUM_COVERAGE_PERCENT ||
  tile.coverage_km >= TEMP_TILE_MINIMUM_COVERAGE_KM;

export const getPinLimit = (zoomLevel: number) => {
  const MIN_PIN_LIMIT = DESKTOP_PINS_LIMIT;
  const roundedZoom = Math.floor(zoomLevel);
  if (roundedZoom >= 8) {
    return MIN_PIN_LIMIT;
  } else {
    const extraPins = Math.abs(8 - roundedZoom) * 10;
    const pinLimit = MIN_PIN_LIMIT + extraPins;
    return Math.min(MAX_PIN_LIMIT, pinLimit);
  }
};
