import { SelectedOption, ShopifyProduct } from '@omniafishing/core';
import _ from 'lodash';
import { getVariantByPartialSelectedOptions } from '../../lib/get_variant_by_selected_options';

const getUnavailableOptionValues = (
  shopifyProduct: ShopifyProduct,
  selectedOptions: SelectedOption[]
) => {
  const optionsWithValues = selectedOptions.filter((option) => !!option.value);
  const optionsWithoutValues = selectedOptions.filter((option) => !option.value);

  const unavailableOptions: Record<string, string[]> = {};

  optionsWithoutValues.forEach((optionWithoutValue) => {
    const name = optionWithoutValue.name;
    const productOptions = shopifyProduct.options.find(
      (option) => option.name.toLowerCase() === name.toLowerCase()
    );
    const valuesWithoutMatches = productOptions.values.filter((value) => {
      const optionsToTest = [
        ...optionsWithValues,
        {
          name,
          value,
        },
      ];
      const match = getVariantByPartialSelectedOptions(shopifyProduct, optionsToTest);
      return !match;
    });

    unavailableOptions[name.toLowerCase()] = valuesWithoutMatches;
  });

  return unavailableOptions;
};

const getOtherAvailableOptionValues = (
  shopifyProduct: ShopifyProduct,
  selectedOptions: SelectedOption[]
) => {
  const unavailableOptions: Record<string, string[]> = {};

  selectedOptions.forEach((currentOption) => {
    const selectedOptionsWithoutThisOption = selectedOptions.filter(
      (option) => option.name !== currentOption.name
    );
    const name = currentOption.name;
    const productOptions = shopifyProduct.options.find(
      (option) => option.name.toLowerCase() === name.toLowerCase()
    );
    const valuesWithoutMatches = productOptions.values.filter((value) => {
      const optionsToTest = [
        ...selectedOptionsWithoutThisOption,
        {
          name,
          value,
        },
      ];
      const match = getVariantByPartialSelectedOptions(shopifyProduct, optionsToTest);
      return !match;
    });

    unavailableOptions[name.toLowerCase()] = valuesWithoutMatches;
  });

  return unavailableOptions;
};

export const isUnavailable = (params: {
  shopifyProduct: ShopifyProduct;
  selectedOptions: SelectedOption[];
  optionName: string;
  value: string;
}) => {
  const { shopifyProduct, selectedOptions, optionName, value } = params;
  let unavailableOptions = getUnavailableOptionValues(shopifyProduct, selectedOptions);
  const optionsWithoutValues = selectedOptions.filter((option) => !option.value);
  if (!optionsWithoutValues.length) {
    unavailableOptions = getOtherAvailableOptionValues(shopifyProduct, selectedOptions);
  }

  const name = optionName.toLowerCase();
  return unavailableOptions[name] && unavailableOptions[name].includes(value);
};

export const isColorInStock = (args: {
  shopifyProduct: ShopifyProduct;
  selectedNonColorOptions: SelectedOption[];
  colorName: string;
}) => {
  const { shopifyProduct, selectedNonColorOptions, colorName } = args;
  const colorMatchedSelectedOptionsVariants = shopifyProduct.variants.edges.filter((edge) => {
    const colorOption = edge.node.selectedOptions.find(
      (option) => option.name.toLowerCase() === 'color'
    );
    const colorMatch = colorOption?.value === colorName;
    const nonColorOptionsMatch = selectedNonColorOptions.every((selectedOption) => {
      const optionMatch = edge.node.selectedOptions.find(
        (option) => option.name === selectedOption.name
      );
      return optionMatch?.value === selectedOption.value;
    });
    return colorMatch && nonColorOptionsMatch;
  });

  return colorMatchedSelectedOptionsVariants.some((variant) => variant.node.availableForSale);
};

export const getColorOptions = (shopifyProduct: ShopifyProduct) => {
  const colorValues = shopifyProduct.variants.edges.map((edge) => {
    const colorOption = edge.node.selectedOptions.find(
      (option) => option.name.toLowerCase() === 'color'
    );
    if (colorOption) {
      return {
        colorName: colorOption.value,
        imgSrc: edge.node.image.url,
      };
    }
  });
  return _.compact(_.uniqBy(colorValues, 'colorName'));
};
