import { Product, ProductFamily, ShopifyProduct, ShopifyVariant } from '@omniafishing/core';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useResponsive } from '../../hooks/use_responsive';
import { getVariantBySelectedOptions } from '../../lib/get_variant_by_selected_options';
import { ProductDetailActions } from '../../redux/product_detail';
import { encodeVariantUrl, RoutePaths } from '../../routes';
import { productFamilyIsBait } from '../../routes/product_detail_page/product_detail_page';
import { ColorSelectorDrawer } from '../color_selector_drawer/color_selector_drawer';
import { OmniaButton } from '../omnia_button/omnia_button';
import { KeitechFatSwingAddOn } from './fat_swing_add_on';
import { fatSwingOptions } from './keitech_fast_swing';
import { NonColorOptionsPicker } from './non_color_options_picker';
import { ProductVariantColorPicker } from './product_variant_color_picker';
import { ProductVariantQuantityAddToCart } from './product_variant_quantity_add_to_cart';
import styles from './product_variant_selector.less';
import { getColorOptions } from './product_variant_selector_helpers';
import { RodAndReelSelect } from './rod_reel_select';

export interface ColorOption {
  colorName: string;
  imgSrc: string;
}

export interface ProductVariantSelectorProps {
  openProductGrid: () => void;
  onColorHover: (colorOption: ColorOption) => void;
  productFamily: ProductFamily;
  selectedVariant: ShopifyVariant;
  shopifyProduct: ShopifyProduct;
  selectedProduct: Product;
}

export const ProductVariantSelector = React.memo((props: ProductVariantSelectorProps) => {
  const {
    openProductGrid,
    onColorHover,
    productFamily,
    selectedProduct,
    selectedVariant,
    shopifyProduct,
  } = props;

  const { isMobile } = useResponsive();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState(selectedVariant.selectedOptions);
  const [variantMatch, setVariantMatch] = useState(true);
  const [hoverColorOption, setHoverColorOption] = useState<ColorOption>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const variantSelected = useCallback(
    (variant: ShopifyVariant) => {
      dispatch(ProductDetailActions.VARIANT_SELECTED(variant));
      setSelectedOptions(variant.selectedOptions);
      setVariantMatch(true);
      const productUrl = encodeVariantUrl(shopifyProduct.handle, variant.selectedOptions);
      history.replace(`${RoutePaths.PRODUCTS}/${productUrl}`);
    },
    [shopifyProduct, selectedOptions]
  );

  const handleVariantChange = useCallback(
    (optionName: string, value: string) => {
      const newSelectedOptions = _.cloneDeep(selectedOptions);
      const optionToChange = newSelectedOptions.find(
        (selectedOption) => selectedOption.name.toLowerCase() === optionName.toLowerCase()
      );
      optionToChange.value = value;

      const optionsWithoutValues = newSelectedOptions.filter((option) => !option.value);
      if (optionsWithoutValues.length) {
        setSelectedOptions(newSelectedOptions);
        setVariantMatch(false);
        return;
      }

      const match = getVariantBySelectedOptions(shopifyProduct, newSelectedOptions);

      if (match) {
        variantSelected(match);
      } else {
        // empty out non-matching options
        newSelectedOptions.forEach((option) => {
          if (option.name.toLowerCase() !== optionName.toLowerCase()) {
            option.value = '';
          }
        });
        setSelectedOptions(newSelectedOptions);
        setVariantMatch(false);
      }
    },
    [selectedOptions, shopifyProduct, variantSelected, getVariantBySelectedOptions]
  );

  const handleColorChange = useCallback(
    (colorName: string) => {
      handleVariantChange('color', colorName);
    },
    [
      selectedOptions,
      shopifyProduct,
      handleVariantChange,
      variantSelected,
      getVariantBySelectedOptions,
    ]
  );

  const colorOptions: ColorOption[] = useMemo(
    () => getColorOptions(shopifyProduct),
    [shopifyProduct.handle]
  );

  const selectedColorOption = useMemo(() => {
    return selectedOptions.find((selectedOption) => selectedOption.name.toLowerCase() === 'color');
  }, [selectedOptions]);

  const nonColorOptions = useMemo(
    () => shopifyProduct.options.filter((option) => option.name.toLowerCase() !== 'color'),
    [shopifyProduct]
  );

  const isFatSwing = fatSwingOptions
    .map((o) => o.handle)
    .includes(selectedProduct?.shopify_product_handle);
  const isRodOrReel =
    selectedProduct?.category_name === 'rods' || selectedProduct?.category_name === 'reels';
  const isProductFamilyBait = productFamilyIsBait(productFamily);

  const showColorDrawer = isProductFamilyBait && isMobile && colorOptions.length >= 10;

  return (
    <section>
      {isRodOrReel ? (
        <RodAndReelSelect
          onVariantSelected={(variant: ShopifyVariant) => {
            variantSelected(variant);
          }}
          productFamily={productFamily}
          selectedVariant={selectedVariant}
          shopifyProduct={shopifyProduct}
        />
      ) : (
        <>
          {
            <div>
              {showColorDrawer ? (
                <ColorSelectorDrawer
                  colorOptions={colorOptions}
                  hoverColorOption={hoverColorOption}
                  isDrawerVisible={isDrawerVisible}
                  nonColorOptions={nonColorOptions}
                  onColorChange={handleColorChange}
                  onVariantChange={handleVariantChange}
                  onVisibleChange={setIsDrawerVisible}
                  productFamily={productFamily}
                  selectedColorOption={selectedColorOption}
                  selectedOptions={selectedOptions}
                  selectedVariant={selectedVariant}
                  shopifyProduct={shopifyProduct}
                />
              ) : (
                <ProductVariantColorPicker
                  colorOptions={colorOptions}
                  hoverColorName={hoverColorOption?.colorName}
                  selectedOptions={selectedOptions}
                  shopifyProduct={shopifyProduct}
                  onColorChange={handleColorChange}
                  onColorHover={(colorOption: ColorOption) => {
                    if (colorOption) {
                      onColorHover(colorOption);
                      setHoverColorOption(colorOption);
                    } else {
                      onColorHover(null);
                      setHoverColorOption(null);
                    }
                  }}
                />
              )}
            </div>
          }
          <div className={styles.buttonsWrapper}>
            <OmniaButton
              kind="link"
              size="sm"
              onPress={openProductGrid}
              style={{ paddingLeft: '0' }}
            >
              Compare All Variations
            </OmniaButton>
            {showColorDrawer && (
              <OmniaButton
                kind="link"
                size="sm"
                onClick={() => setIsDrawerVisible(true)}
                style={{ paddingLeft: '0' }}
              >
                See All {colorOptions.length} Colors
              </OmniaButton>
            )}
          </div>
          {isFatSwing ? (
            <KeitechFatSwingAddOn selectedProduct={selectedProduct} />
          ) : (
            <NonColorOptionsPicker
              nonColorOptions={nonColorOptions}
              onVariantChange={handleVariantChange}
              selectedOptions={selectedOptions}
              shopifyProduct={shopifyProduct}
            />
          )}
        </>
      )}
      <ProductVariantQuantityAddToCart
        openProductGrid={openProductGrid}
        productFamily={productFamily}
        selectedProduct={selectedProduct}
        selectedVariant={selectedVariant}
        shopifyProduct={shopifyProduct}
        variantMatch={variantMatch}
      />
    </section>
  );
});
