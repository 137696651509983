import {
  EnvironmentOutlined,
  MailOutlined,
  RightOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Brand, FishingReport, Product, ProductFamily } from '@omniafishing/core';
import { Divider, Select, Tooltip } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useCart } from '../../hooks/use_cart';
import { useResponsive } from '../../hooks/use_responsive';
import { useUser } from '../../hooks/use_user';
import { useUserPreferences } from '../../hooks/use_user_preferences';
import { useUserWaterbodies } from '../../hooks/use_user_waterbodies';
import { addToCartText } from '../../lib/add_to_cart_text';
import { apiV1 } from '../../lib/api';
import { deepClone } from '../../lib/deep_clone';
import { getProductBySelectedOptions } from '../../lib/get_product_by_selected_options';
import { getImgixPath } from '../../lib/imgix';
import { customAttributesToAttributionQueryParams } from '../../lib/line_item_attribution';
import { isProductInStock } from '../../lib/products';
import { setShopifyImgWidth } from '../../lib/set_shopify_img_width';
import { toDollars } from '../../lib/to_dollars';
import { OmniaUrls } from '../../lib/urls';
import { WebAnalytics, WebAnalyticsEventAreas } from '../../lib/web_analytics';
import { getIpLatitude, getIpLongitude } from '../../redux/geographic_location';
import { InventoryModalActions } from '../../redux/inventory_modal';
import {
  getLineItemAttribution,
  getOnCartAdd,
  getSelectedProductSku,
  QuickModalContexts,
} from '../../redux/product_family_quick_modal';
import { RoutePaths } from '../../routes';
import { ProductDetailPageHashes } from '../../routes/product_detail_page/product_detail_page';
import { ClarityRange } from '../clarity_range/clarity_range';
import { OmniaButton } from '../omnia_button/omnia_button';
import { OmniaLinkButton } from '../omnia_button/omnia_link_button';
import { ProductCarryModal } from '../product_carry_modal/product_carry_modal';
import { FishingReportsWidget } from '../product_variant_selector/fishing_reports_widget';
import { ColorOption } from '../product_variant_selector/product_variant_selector';
import { QuantityInput } from '../quantity_input/quantity_input';
import { ShippingEstimator } from '../shipping_estimator/shipping_estimator';
import { SpanLink } from '../span_link/span_link';
import SvgShipped from '../svg/shipped';
import { ProductTitle } from './product_title';
import styles from './quick_select_variant_selector.less';
import {
  getColorOptionAvailability,
  getProductFamilyColorOptions,
  getProductFamilyOptionsFromProducts,
  getSelectedColorOption,
  isUnavailable,
} from './quick_select_variant_selector_helpers';

interface QuickSelectVariantSelectorProps {
  productFamily: ProductFamily;
  products: Product[];
  brand: Brand;
  context: QuickModalContexts;
  containsAllColors: boolean;
  recommendation_id: string;
  fishingReports: FishingReport[];
  list_uuid: string;
}

export const QuickSelectVariantSelector = (props: QuickSelectVariantSelectorProps) => {
  const {
    products,
    productFamily,
    context,
    containsAllColors,
    recommendation_id,
    brand,
    fishingReports,
    list_uuid,
  } = props;

  const { isMobile } = useResponsive();
  const lineItemAttribution = useSelector(getLineItemAttribution);
  const onCartAdd = useSelector(getOnCartAdd);
  const dispatch = useDispatch();
  const selectedProductSku = useSelector(getSelectedProductSku);
  const productBySku = products.find((p) => p.sku === selectedProductSku);
  const firstInStockProduct = products.find((p) => isProductInStock(p));
  const defaultSelectedProduct = productBySku || firstInStockProduct || products[0];
  const [selectedProduct, setSelectedProduct] = useState(defaultSelectedProduct);
  const [selectedOptions, setSelectedOptions] = useState(defaultSelectedProduct.shopify_options);
  const [productCarryModalOpen, setProductCarryModalOpen] = useState(false);
  const [variantMatch, setVariantMatch] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart();
  const { userPreferencesWaterbodies } = useUserPreferences();
  const { userWaterbodies } = useUserWaterbodies();
  const ipLatitude = useSelector(getIpLatitude);
  const ipLongitude = useSelector(getIpLongitude);
  const productFamilyOptions = getProductFamilyOptionsFromProducts(productFamily, products);
  const [fishingReportsNearby, setFishingReportsNearby] = useState<FishingReport[]>([]);
  const [hoverColor, setHoverColor] = useState<ColorOption>(null);
  const history = useHistory();
  const { isPro } = useUser();
  const wrapperRef = useRef();

  useEffect(() => {
    if (ipLongitude && ipLatitude) {
      setFishingReportsNearby([]);
      apiV1
        .productFishingReportsNearby({
          sku: selectedProduct.sku,
          lat: ipLatitude,
          lng: ipLongitude,
        })
        .then((res) => {
          setFishingReportsNearby(res.data.data);
        });
    }
  }, [selectedProduct.sku, ipLatitude, ipLongitude]);

  const onClickAddToCart = () => {
    const customAttributes = [];
    if (lineItemAttribution != null) {
      customAttributes.push(...lineItemAttribution);
    }

    addToCart({
      productsToAdd: [
        {
          product: selectedProduct,
          quantity,
        },
      ],
      customAttributes,
      notification: 'flash',
      message: `${selectedProduct.title} Added to Cart`,
      addToCartArea: WebAnalyticsEventAreas.QUICK_VIEW,
      position: null,
      list_uuid,
    });
    onCartAdd?.();
  };

  const onOutOfStockClick = () => {
    dispatch(
      InventoryModalActions.INVENTORY_MODAL_OPEN(selectedProduct, WebAnalyticsEventAreas.QUICK_VIEW)
    );
  };

  const onCarryClick = () => {
    setProductCarryModalOpen(true);
  };

  const onCarryModalCancel = () => {
    setProductCarryModalOpen(false);
  };

  const onColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleVariantChange('color', event.target.value);
  };

  const onOptionChange = (optionName: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleVariantChange(optionName, event.target.value);
  };

  const onQuantityChange = (val: number) => {
    setQuantity(val);
  };

  const productSelected = (product: Product) => {
    setSelectedProduct(product);
    setSelectedOptions(product.shopify_options);
    setVariantMatch(true);
  };

  const handleVariantChange = (optionName: string, value: string) => {
    const newSelectedOptions = deepClone(selectedOptions);
    const optionToChange = newSelectedOptions.filter(
      (selectedOption) => selectedOption.name.toLowerCase() === optionName.toLowerCase()
    )[0];
    optionToChange.value = value;

    const optionsWithoutValues = newSelectedOptions.filter((option) => !option.value);
    if (optionsWithoutValues.length) {
      setSelectedOptions(newSelectedOptions);
      setVariantMatch(false);

      return;
    }

    const match = getProductBySelectedOptions(products, newSelectedOptions);

    if (match) {
      productSelected(match);
    } else {
      // empty out non-matching options
      newSelectedOptions.forEach((option) => {
        if (option.name.toLowerCase() !== optionName.toLowerCase()) {
          option.value = '';
        }
      });
      setSelectedOptions(newSelectedOptions);
      setVariantMatch(false);
    }
  };

  const colorOptions: ColorOption[] = useMemo(() => {
    return getProductFamilyColorOptions(productFamilyOptions);
  }, [productFamily]);

  const selectedColorOption = getSelectedColorOption(selectedOptions);

  const allColorOptions = useMemo(
    () =>
      getColorOptionAvailability({
        colorOptions,
        products,
        selectedOptions,
        productFamilyOptions,
      }),
    [
      products.map((p) => p.sku).join(),
      selectedOptions.map((o) => o.value).join(),
      colorOptions.map((o) => o.colorName).join(),
      productFamilyOptions.map((o) => o.name).join(),
    ]
  );

  const latestWaterbody = userPreferencesWaterbodies[0];
  const clarityValue = latestWaterbody?.omnia_clarity;
  const productsContainsClarity = selectedProduct?.clarities.includes(clarityValue);

  const nonColorOptions = productFamilyOptions.filter((o) => o.name.toLowerCase() !== 'color');
  const hasColorOption = !!colorOptions.length;
  const showImage = !hasColorOption || !isMobile;
  const hasInventory =
    (selectedProduct?.inventory != null && selectedProduct?.inventory_tracked) ||
    !selectedProduct?.inventory_tracked;
  const inStock = isProductInStock(selectedProduct);
  const outOfStock = !inStock;
  const inventoryText =
    selectedProduct.inventory > 5 || !selectedProduct.inventory_tracked
      ? '5+'
      : selectedProduct.inventory;
  const isHidden = selectedProduct?.hidden;

  const addCartText = addToCartText({ outOfStock, variantMatch, isHidden, productFamily: true });

  const isNewRelease = productFamily.tags.indexOf('new_releases') > -1;

  const userIsProAndHasProPrice = isPro && productFamily.price_pro != null;
  const hasSalePrice = selectedProduct.on_sale || userIsProAndHasProPrice;
  const selectedProductPrice = userIsProAndHasProPrice
    ? productFamily.price_pro
    : parseFloat(selectedProduct.price);
  const selectedProductCompareAtPrice = Math.max(
    parseFloat(selectedProduct.compare_at_price || selectedProduct.price),
    parseFloat(selectedProduct.price)
  );
  const saleValue = toDollars(selectedProductCompareAtPrice - selectedProductPrice);

  const inputDisabled = !variantMatch || outOfStock || isHidden;
  const isRodOrReel =
    selectedProduct.category_name === 'rods' || selectedProduct?.category_name === 'reels';
  const selectOptions = products.map((p) => ({
    label: p.shopify_options.map((o) => o.value).join(' / '),
    value: p.sku,
  }));

  const onViewAllReportsClick = useCallback(() => {
    history.push({
      pathname: OmniaUrls.productFamily(productFamily.handle),
      search: `?${customAttributesToAttributionQueryParams(lineItemAttribution)}`,
      hash: ProductDetailPageHashes.fishing_reports,
    });
  }, [history, productFamily, lineItemAttribution]);

  return (
    <section ref={wrapperRef}>
      <div className={styles.headingWrapper}>
        <div>
          <h1 className={styles.heading} id={selectedProduct.sku}>
            {productFamily.title}
            {isNewRelease && <span className={styles.newReleaseTag}>NEW RELEASE</span>}
          </h1>
          <ProductTitle
            selectedProduct={selectedProduct}
            fishingReports={fishingReports}
            fishingReportsNearby={fishingReportsNearby}
            userWaterbodies={userWaterbodies}
          />
          {fishingReports.length > 0 && (
            <FishingReportsWidget
              fishingReports={fishingReports}
              products={products}
              onViewAllReportsClick={onViewAllReportsClick}
              placement="bottomLeft"
            />
          )}
        </div>
        {brand && brand.image && (
          <div className={styles.brandImg}>
            <Link
              to={`${RoutePaths.BRANDS}/${brand.url_slug}`}
              className={styles.brandImgLink}
              style={{ backgroundImage: `url(${getImgixPath(brand.image, { w: 150 })})` }}
            ></Link>
          </div>
        )}
      </div>

      <Divider className={styles.dividerNoMargin} />

      <div className={styles.options}>
        {showImage && (
          <div className={styles.imgWrapper}>
            <img
              src={
                hoverColor
                  ? setShopifyImgWidth(hoverColor.imgSrc, 300)
                  : setShopifyImgWidth(selectedProduct.img_url, 300)
              }
            />
          </div>
        )}
        <div
          className={classNames(styles.optionsSelector, {
            [styles.optionsSelector__hasColors]: !showImage,
          })}
        >
          {isRodOrReel ? (
            <>
              <span className={classNames(styles.optionLegend, styles.optionLegend__variantSelect)}>
                Choose model:
              </span>
              <Select
                className={styles.variantSelect}
                value={selectedProduct.sku}
                options={selectOptions}
                onChange={(sku: string) => {
                  const product = products.find((p) => p.sku === sku);
                  if (product) {
                    productSelected(product);
                  }
                }}
              />
            </>
          ) : (
            <>
              {hasColorOption && (
                <fieldset className={styles.fieldset}>
                  <legend className={styles.optionLegend__color}>
                    {context === 'lake' ? 'Best colors for this lake:' : 'Color:'}{' '}
                    <span className={styles.optionName}>
                      {hoverColor ? hoverColor.colorName : selectedColorOption.value}{' '}
                    </span>
                  </legend>
                  {selectedProduct && selectedProduct.clarities.length > 0 && (
                    <div className={styles.clarityParent}>
                      <div className={styles.clarityLabel}>
                        {' '}
                        Recommended Water Clarity:
                        <ClarityRange clarities={selectedProduct.clarities} />
                      </div>
                      {clarityValue != null && productsContainsClarity && (
                        <div className={styles.clarityLink}>
                          <Link to={OmniaUrls.waterbody(latestWaterbody)} target={'_blank'}>
                            <EnvironmentOutlined className={styles.svgIcon} />
                            <SpanLink className={styles.linkTitle}>
                              {latestWaterbody.primary_name}
                            </SpanLink>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                  <div className={styles.colorsWrapper}>
                    {allColorOptions.map((colorOption) => {
                      const isSelected = selectedColorOption.value === colorOption.colorName;
                      return (
                        <label
                          className={styles.colorLabel}
                          key={colorOption.colorName}
                          onMouseEnter={() => {
                            setHoverColor({
                              colorName: colorOption.colorName,
                              imgSrc: colorOption.imgSrc,
                            });
                          }}
                          onMouseLeave={() => {
                            setHoverColor(null);
                          }}
                        >
                          <div>
                            <img
                              alt={colorOption.colorName}
                              src={setShopifyImgWidth(colorOption.imgSrc, 300)}
                              title={colorOption.colorName}
                              className={classNames(styles.colorLabel, {
                                [styles.optionUnavailable]: !colorOption.available,
                                [styles.optionSelected]: isSelected,
                              })}
                            />
                          </div>
                          <input
                            type="radio"
                            name="color"
                            value={colorOption.colorName}
                            checked={isSelected}
                            onChange={onColorChange}
                            className={styles.optionRadio}
                          />
                        </label>
                      );
                    })}
                  </div>
                  {context === 'lake' && !containsAllColors && (
                    <Link
                      to={OmniaUrls.productFamily(productFamily.handle)}
                      onClick={() => {
                        WebAnalytics.productClick({
                          productOrProductFamily: productFamily,
                          show_modal: false,
                          position: null,
                          recommendation_id,
                        });
                      }}
                      style={{
                        marginTop: 12,
                        display: 'inline-block',
                      }}
                    >
                      More colors available
                    </Link>
                  )}
                </fieldset>
              )}

              {nonColorOptions.map((option) => {
                const selectedOption = selectedOptions.filter(
                  (stateSelectedOption) => stateSelectedOption.name === option.name
                )[0];
                return (
                  <fieldset className={styles.fieldset} key={option.name}>
                    <legend className={styles.optionLegend}>
                      {_.capitalize(option.name)}:{' '}
                      <span className={styles.optionName}>{selectedOption.value} </span>
                    </legend>

                    {option.values
                      .map((value) => value.value)
                      .map((value) => {
                        const valueIsUnavailable = isUnavailable({
                          products,
                          productFamilyOptions,
                          selectedOptions,
                          optionName: option.name,
                          value,
                        });
                        const isSelected = selectedOption.value === value;
                        return (
                          <label
                            key={value}
                            className={classNames(styles.textLabel, {
                              [styles.optionUnavailable]: valueIsUnavailable,
                              [styles.optionSelected]: isSelected,
                            })}
                          >
                            <span>{value}</span>
                            <input
                              type="radio"
                              name={option.name}
                              value={value}
                              checked={isSelected}
                              onChange={onOptionChange(option.name)}
                              className={styles.optionRadio}
                            />
                          </label>
                        );
                      })}
                  </fieldset>
                );
              })}
            </>
          )}
          {hasInventory && <p className={styles.stock}>Stock: {inventoryText}</p>}

          <Divider type="horizontal" />

          <div>
            <div className={styles.priceQuantity}>
              <p className={styles.price}>
                {hasSalePrice ? (
                  <span className={styles.priceContainer}>
                    <span className={styles.price__sale}>
                      {userIsProAndHasProPrice ? 'PRO price' : 'SALE'}{' '}
                      {toDollars(selectedProductPrice)}
                    </span>
                    <span className={styles.originalPrice}>
                      Original Price:
                      <s className={styles.priceCompare}>
                        {toDollars(selectedProductCompareAtPrice)}
                      </s>
                    </span>
                    <span className={classNames(styles.price__sale, styles.originalPrice)}>
                      Save {saleValue}
                    </span>
                  </span>
                ) : (
                  <span>{toDollars(selectedProductPrice)}</span>
                )}
              </p>

              <div className={styles.quantityWrapper}>
                <QuantityInput
                  onChange={onQuantityChange}
                  disabled={inputDisabled}
                  quantity={quantity}
                  max={
                    hasInventory && selectedProduct.inventory > 0 ? selectedProduct.inventory : 99
                  }
                />
              </div>
            </div>
          </div>

          <div className={styles.detailLink}>
            {hasColorOption && context === 'lake' && (
              <div>
                <p style={{ margin: 0 }} className={styles.optionLegend}>
                  ALL COLOR OPTIONS:
                </p>
                <ul className={styles.allColors}>
                  {productFamily.options
                    .filter((option) => option.name.toLowerCase() === 'color')[0]
                    .values.map((colorOption) => {
                      return (
                        <li className={styles.allColorsItem} key={colorOption.value}>
                          <Link
                            to={`${RoutePaths.PRODUCTS}/${productFamily.handle}`}
                            onClick={() => {
                              WebAnalytics.productClick({
                                productOrProductFamily: productFamily,
                                show_modal: false,
                                position: null,
                                recommendation_id,
                              });
                            }}
                          >
                            <img
                              alt={colorOption.value}
                              src={setShopifyImgWidth(colorOption.src, 100)}
                              title={colorOption.value}
                            />
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <Divider className={styles.dividerNoTopMargin} />

      <div className={styles.bottomButtons}>
        {inStock ? (
          <OmniaButton
            onPress={onClickAddToCart}
            kind="primary"
            size="lg"
            fontSize={14}
            isDisabled={inputDisabled}
            data-test="add_to_cart"
            block={isMobile}
          >
            <ShoppingCartOutlined />
            {addCartText}
          </OmniaButton>
        ) : (
          !isHidden && (
            <Tooltip title="Sign up to be emailed as soon as it's back in stock">
              <div>
                <OmniaButton
                  size="lg"
                  kind="primary"
                  fontSize={14}
                  onPress={onOutOfStockClick}
                  block={isMobile}
                >
                  <MailOutlined />
                  Email Me When In Stock
                </OmniaButton>
              </div>
            </Tooltip>
          )
        )}

        {isHidden && (
          <>
            <Tooltip title="Let us know you want this product stocked">
              <div>
                <OmniaButton kind="tertiary" size="lg" fontSize={14} onPress={onCarryClick}>
                  <MailOutlined />I want this
                </OmniaButton>
              </div>
            </Tooltip>
            <ProductCarryModal
              open={productCarryModalOpen}
              product={selectedProduct}
              onClose={onCarryModalCancel}
            />
          </>
        )}

        <OmniaLinkButton
          to={
            OmniaUrls.productFamily(productFamily.handle) +
            `?${customAttributesToAttributionQueryParams(lineItemAttribution)}`
          }
          size="lg"
          kind="tertiary"
          fontSize={14}
          onPress={() => {
            WebAnalytics.productClick({
              productOrProductFamily: productFamily,
              show_modal: false,
              position: null,
              recommendation_id,
            });
          }}
        >
          See Full Product Details <RightOutlined />
        </OmniaLinkButton>
      </div>

      <Divider />

      <div className={styles.shippingContainer}>
        <SvgShipped height="2.2em" width="2.2em" />
        <p className={styles.shipFast}>
          <strong>We ship fast!</strong>
          <br />
          {selectedProduct.inventory_tracked ? (
            <span>Orders placed before 1pm Central ship same day.</span>
          ) : (
            <span className={styles.shipDrop}>
              <em>This item will ship directly from the manufacturer. Shipping times may vary.</em>
            </span>
          )}
        </p>
        {selectedProduct.inventory_tracked && (
          <ShippingEstimator
            product={selectedProduct}
            className={styles.shippingEstimate}
            availableForSale={!inputDisabled}
          />
        )}
      </div>
    </section>
  );
};
