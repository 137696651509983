import { EnvironmentOutlined } from '@ant-design/icons';
import mapboxgl from 'mapbox-gl';
import React, { SVGProps } from 'react';
import airTempImg from '../../routes/map_page/assets/air_temp_image.jpg';
import boatLandingsImg from '../../routes/map_page/assets/boat_landings_image.jpg';
import bottomHardnessImg from '../../routes/map_page/assets/bottom_hardness_image.jpg';
import historicalWaterTempImg from '../../routes/map_page/assets/hi_res_historical_water_temp_image.jpg';
import lightningImg from '../../routes/map_page/assets/lightning_image.jpg';
import precipitationImg from '../../routes/map_page/assets/precipitation_image.jpg';
import contoursImg from '../../routes/map_page/assets/social_map_charts_image.jpg';
import stormCellsImg from '../../routes/map_page/assets/storm_cells_image.jpg';
import vegetationImg from '../../routes/map_page/assets/vegetation_image.jpg';
import waterbodyPins from '../../routes/map_page/assets/waterbody_pins_image.jpg';
import waterClarityImg from '../../routes/map_page/assets/water_clarity_image.jpg';
import waypointsImg from '../../routes/map_page/assets/waypoints_image.jpg';
import windImg from '../../routes/map_page/assets/wind_direction_image.jpg';
import satellite_map from '../map/map_modals/layers_modal/satellite_map.png';
import standard_map from '../map/map_modals/layers_modal/standard_map.png';
import { WaypointsDescription } from '../map_default/waypoints_description';
import SvgCmapIcon from '../svg/cmap_icon';
import SvgLayerAirTemp from '../svg/layer_air_temp';
import SvgLayerBoatRamp from '../svg/layer_boat_ramp';
import SvgLayerDepthContours from '../svg/layer_depth_contours';
import SvgLayerLightning from '../svg/layer_lightning';
import SvgLayerMapPin from '../svg/layer_map_pin';
import SvgLayerPrecipitation from '../svg/layer_precipitation';
import SvgLayerStorm from '../svg/layer_storm';
import SvgLayerVegetation from '../svg/layer_vegetation';
import SvgLayerWaterClarity from '../svg/layer_water_clarity';
import SvgLayerWaterTemp from '../svg/layer_water_temp';
import SvgLayerWind from '../svg/layer_wind';
import airTemperature from './layer_detail_images/air_temp.jpg';
import cMAPSocialBottomHardness from './layer_detail_images/bottom_hardness.jpg';
import cMAPSocialMapCharts from './layer_detail_images/depth_contours.jpg';
import lightning from './layer_detail_images/lightning.jpg';
import radar from './layer_detail_images/precipitation.jpg';
import proVideoThumb from './layer_detail_images/pro_video_thumbnail.jpg';
import stormCells from './layer_detail_images/storm_cells.jpg';
import cMAPSocialVegetation from './layer_detail_images/vegetation.jpg';
import wind from './layer_detail_images/wind.jpg';

// TODO: Move OVERLAY layer (lake pins) to POINTS_OF_INTEREST
// AND completely remove OVERLAY type etc
export enum LAYER_TYPES {
  BASE = 'BASE',
  CORE = 'CORE',
  OVERLAY = 'OVERLAY',
  WEATHER = 'WEATHER',
  POINTS_OF_INTEREST = 'POINTS_OF_INTEREST',
}

export type ALL_LAYER_TYPES =
  | CORE_LAYER
  | OVERLAY_LAYER
  | WEATHER_LAYER
  | POINTS_OF_INTEREST_LAYER
  | BASE_LAYER;

export interface LayerInfo {
  layerType: LAYER_TYPES;
  name: string;
  icon: React.ComponentType<SVGProps<SVGSVGElement>>;
  imgSrc: string;
  tabsIcon?: any;
  description: string | React.ReactNode;
  value: ALL_LAYER_TYPES;
  muxPlaybackId?: string;
  thumbnail?: string;
  order?: number;
}

export interface MapLayerState {
  [LAYER_TYPES.BASE]: BASE_LAYER;
  [LAYER_TYPES.CORE]: CORE_LAYER | undefined;
  [LAYER_TYPES.OVERLAY]: OVERLAY_LAYER[];
  [LAYER_TYPES.WEATHER]: WEATHER_LAYER[];
  [LAYER_TYPES.POINTS_OF_INTEREST]: POINTS_OF_INTEREST_LAYER[];
}

export enum BASE_LAYER {
  STANDARD = 'STANDARD',
  SATELLITE = 'SATELLITE',
}

export type BASE_INFO = {
  layerType: LAYER_TYPES.BASE;
  name: string;
  value: BASE_LAYER;
  imgSrc: string;
};

export const BASE_LAYER_INFO: Record<BASE_LAYER, BASE_INFO> = {
  [BASE_LAYER.STANDARD]: {
    layerType: LAYER_TYPES.BASE,
    name: 'Standard',
    value: BASE_LAYER.STANDARD,
    imgSrc: standard_map,
  },
  [BASE_LAYER.SATELLITE]: {
    layerType: LAYER_TYPES.BASE,
    name: 'Satellite',
    value: BASE_LAYER.SATELLITE,
    imgSrc: satellite_map,
  },
};

export enum CORE_LAYER {
  CONTOUR_DEPTH = 'CONTOUR_DEPTH',
  CONTOUR_HARDNESS = 'CONTOUR_HARDNESS',
  OMNIA_WATER_TEMP = 'OMNIA_WATER_TEMP',
  // WATER_TEMP_REAL_TIME = 'WATER_TEMP_REAL_TIME',
  CLARITY = 'CLARITY',
  VEGETATION = 'VEGETATION',
}
export type CORE_INFO = LayerInfo;

export function isCoreLayer(layer: any): layer is CORE_LAYER {
  return Object.values(CORE_LAYER).includes(layer);
}

export function isWeatherLayer(layer: any): layer is WEATHER_LAYER {
  return Object.values(WEATHER_LAYER).includes(layer);
}

export const CORE_LAYER_INFO: Record<CORE_LAYER, CORE_INFO> = {
  [CORE_LAYER.CONTOUR_DEPTH]: {
    layerType: LAYER_TYPES.CORE,
    name: 'C-MAP Social Contours',
    value: CORE_LAYER.CONTOUR_DEPTH,
    icon: SvgCmapIcon,
    imgSrc: contoursImg,
    tabsIcon: SvgLayerDepthContours,
    muxPlaybackId: '9zOA4JjWaIhurY00Xi3WsuenRCwoF5qDehp2875rr3cw',
    thumbnail: cMAPSocialMapCharts,
    order: 0,
    description:
      'Use these contours to key features like ledges, submerged humps and points that hold fish at different times of the year.  These C-Map Social Map Contours are provided by our friends at Lowrance.  This data is processed from user submitted sonar log files. These contours do not provide complete coverage for an entire lake or river and instead only build where files are submitted.  The detail where coverage is available can exceed the details found in even the best maps on the market. For complete US and Canada contours, turn on our Navionics layer.',
  },
  [CORE_LAYER.CONTOUR_HARDNESS]: {
    layerType: LAYER_TYPES.CORE,
    name: 'C-MAP Social Bottom Hardness',
    value: CORE_LAYER.CONTOUR_HARDNESS,
    icon: SvgLayerDepthContours,
    imgSrc: bottomHardnessImg,
    tabsIcon: SvgLayerDepthContours,
    muxPlaybackId: 'J00xWo00vsgynzXA2tX8GToUbU5ZFxKtBk3SV02k6t42v4',
    thumbnail: cMAPSocialBottomHardness,
    order: 2,
    description:
      'Bottom hardness provides a relative scale between rock and soft bottom muck.  Use these layers to find fish and transition sections.  Our bottom hardness layer is provided by our friends at Lowrance as part of their C-Map Genesis program.  Similar to the C-Map Social contour and vegetation layers, bottom hardness is provided only in areas where Lowrance users have submitted sonar logs as part of the Social Map program.',
  },
  [CORE_LAYER.VEGETATION]: {
    layerType: LAYER_TYPES.CORE,
    name: 'C-MAP Social Vegetation',
    value: CORE_LAYER.VEGETATION,
    icon: SvgLayerVegetation,
    imgSrc: vegetationImg,
    tabsIcon: SvgLayerDepthContours,
    muxPlaybackId: 'nUq7BJl8VNZ82PHtOahgleplT3HYV3jF7Y01mXYn3jcs',
    thumbnail: cMAPSocialVegetation,
    order: 1,
    description:
      'Use this layer to run weed lines or find submerged plants that can operate as feeding and ambush grounds for predator fish.  Our C-Map Social Map Vegetation layer is provided by our friends at Lowrance as part of their C-Map Genesis program. Similar to the C-Map Social contour and bottom hardness layers, vegetation data is provided only in areas where Lowrance users have submitted sonar logs as part of the Social Map program. We provide contours as a backdrop to demonstrate where there’s coverage along with green where plants have been found and blank where plants were not mapped with sonar.',
  },
  [CORE_LAYER.OMNIA_WATER_TEMP]: {
    layerType: LAYER_TYPES.CORE,
    name: 'High Res Historical Water Temp',
    value: CORE_LAYER.OMNIA_WATER_TEMP,
    icon: SvgLayerWaterTemp,
    imgSrc: historicalWaterTempImg,
    tabsIcon: SvgLayerWaterTemp,
    muxPlaybackId: 'rtsZ81wT7tllQuR4Jl4QaShXaOoOw23COQXAHXZshaw',
    thumbnail: proVideoThumb,
    order: 4,
    description:
      'We process satellite data for near-real-time lake and river surface temps to show average current water temperature and differences in water temp throughout the waterbody.  This can be useful for patterning fish behavior and periods of activity like spawn or heavy feeding as lakes warm and cool during the year. Water temperature forecasting models in development.',
  },
  // [CORE_LAYER.WATER_TEMP_REAL_TIME]: {
  //   layerType: LAYER_TYPES.CORE,
  //   name: 'Real-Time Average Water Temp',
  //   value: CORE_LAYER.WATER_TEMP_REAL_TIME,
  //   icon: SvgLayerWaterTempRealTime,
  //   imgSrc: realTimeWaterTempImg,
  //   tabsIcon: SvgLayerWaterTempRealTime,
  //   muxPlaybackId: 'rtsZ81wT7tllQuR4Jl4QaShXaOoOw23COQXAHXZshaw',
  //   thumbnail: proVideoThumb,
  //   order: 3,
  //   description:
  //     'We process satellite data for near-real-time lake and river surface temps to show average current water temperature and differences in water temp throughout the waterbody.  This can be useful for patterning fish behavior and periods of activity like spawn or heavy feeding as lakes warm and cool during the year. Water temperature forecasting models in development.',
  // },
  [CORE_LAYER.CLARITY]: {
    layerType: LAYER_TYPES.CORE,
    name: 'Water Clarity',
    value: CORE_LAYER.CLARITY,
    icon: SvgLayerWaterClarity,
    imgSrc: waterClarityImg,
    tabsIcon: SvgLayerWaterClarity,
    muxPlaybackId: 'UiWtvozgI3DG7JW1X33w8h6ZsskNz3rLIH02pNiFu4jA',
    thumbnail: proVideoThumb,
    order: 5,
    description:
      'We process satellite data for near-real-time lake and river surface temps to show current and recent water clarity.  This helps you stay in informed about your lake after weather events like storms or algal blooms. Clarity is displayed as a color gradient so you can see where water is clear, murky or somewhere in between.',
  },
};

export enum WEATHER_LAYER {
  WIND = 'WIND',
  RADAR = 'RADAR',
  AIR_TEMP = 'AIR_TEMP',
  LIGHTNING = 'LIGHTNING',
  STORMS = 'STORMS',
}

export type WEATHER_INFO = LayerInfo;

export const WEATHER_LAYER_INFO: Record<WEATHER_LAYER, WEATHER_INFO> = {
  [WEATHER_LAYER.WIND]: {
    layerType: LAYER_TYPES.WEATHER,
    name: 'Wind',
    value: WEATHER_LAYER.WIND,
    icon: SvgLayerWind,
    imgSrc: windImg,
    tabsIcon: SvgLayerWind,
    thumbnail: wind,
    muxPlaybackId: '02wXP3lBe02uR01QTs2HJ7TCADn02LW48Lf2IKhsSRS02KGk',
    order: 0,
    description: 'Granular data on the strength and direction the wind is moving.',
  },
  [WEATHER_LAYER.RADAR]: {
    layerType: LAYER_TYPES.WEATHER,
    name: 'Radar',
    value: WEATHER_LAYER.RADAR,
    icon: SvgLayerPrecipitation,
    imgSrc: precipitationImg,
    thumbnail: radar,
    order: 1,
    description:
      'Track real-time rainfall with our radar layer. Plan and adapt your activities based on the latest updates.',
    muxPlaybackId: 'TqWrGG4xnOcRgx2FrNCH9cqngjhW6sblYUvjtzGvAvo',
  },
  [WEATHER_LAYER.STORMS]: {
    layerType: LAYER_TYPES.WEATHER,
    name: 'Storm Cells',
    value: WEATHER_LAYER.STORMS,
    icon: SvgLayerStorm,
    imgSrc: stormCellsImg,
    thumbnail: stormCells,
    order: 2,
    description: 'Ensure safety by being conscious of approaching weather conditions.',
    muxPlaybackId: 'TqWrGG4xnOcRgx2FrNCH9cqngjhW6sblYUvjtzGvAvo',
  },
  [WEATHER_LAYER.LIGHTNING]: {
    layerType: LAYER_TYPES.WEATHER,
    name: 'Lightning',
    value: WEATHER_LAYER.LIGHTNING,
    icon: SvgLayerLightning,
    imgSrc: lightningImg,
    thumbnail: lightning,
    order: 3,
    description: 'Recently recorded strikes are presented at their location on the map.',
    muxPlaybackId: 'TqWrGG4xnOcRgx2FrNCH9cqngjhW6sblYUvjtzGvAvo',
  },
  [WEATHER_LAYER.AIR_TEMP]: {
    layerType: LAYER_TYPES.WEATHER,
    name: 'Air Temperature',
    value: WEATHER_LAYER.AIR_TEMP,
    icon: SvgLayerAirTemp,
    imgSrc: airTempImg,
    tabsIcon: SvgLayerAirTemp,
    thumbnail: airTemperature,
    order: 4,
    description: 'Incorporate air temperature when deciding on fishing locations and timings.',
    muxPlaybackId: 'TqWrGG4xnOcRgx2FrNCH9cqngjhW6sblYUvjtzGvAvo',
  },
};

export enum POINTS_OF_INTEREST_LAYER {
  BOAT_LANDINGS = 'BOAT_LANDINGS',
  WAYPOINTS = 'WAYPOINTS',
}

export type POINTS_OF_INTEREST_INFO = LayerInfo;

export const POINTS_OF_INTEREST_LAYER_INFO: Record<
  POINTS_OF_INTEREST_LAYER,
  POINTS_OF_INTEREST_INFO
> = {
  [POINTS_OF_INTEREST_LAYER.BOAT_LANDINGS]: {
    layerType: LAYER_TYPES.POINTS_OF_INTEREST,
    name: 'Boat Landings',
    value: POINTS_OF_INTEREST_LAYER.BOAT_LANDINGS,
    icon: SvgLayerBoatRamp,
    imgSrc: boatLandingsImg,
    description:
      'Locate both public and private boat landings and ramps to help you plan your next fishing trip.',
  },
  [POINTS_OF_INTEREST_LAYER.WAYPOINTS]: {
    layerType: LAYER_TYPES.POINTS_OF_INTEREST,
    name: 'Waypoints',
    value: POINTS_OF_INTEREST_LAYER.WAYPOINTS,
    icon: EnvironmentOutlined as any,
    imgSrc: waypointsImg,
    description: <WaypointsDescription />,
  },
};

export enum OVERLAY_LAYER {
  PINS = 'PINS',
}
export type OVERLAY_INFO = LayerInfo;

export const OVERLAY_LAYER_INFO: Record<OVERLAY_LAYER, OVERLAY_INFO> = {
  [OVERLAY_LAYER.PINS]: {
    layerType: LAYER_TYPES.OVERLAY,
    name: 'Lake Pins',
    value: OVERLAY_LAYER.PINS,
    thumbnail: proVideoThumb,
    icon: SvgLayerMapPin,
    imgSrc: waterbodyPins,
    description:
      'Display pin locations on the map, making it easier to navigate and find the information you need.',
  },
};

// There is probably a type we can take from the repo, but this works for now
export interface AerisController {
  addWeatherLayer: (layer: string, options?: any) => void;
  hasWeatherLayer: (layer: string) => boolean;
  getWeatherLayer: (layer: string) => any;
  removeWeatherLayer: (layer: string) => void;
  addDataInspectorControl: () => void;
  removeDataInspectorControl: () => void;
  controls: any;
  timeline: {
    currentDate: Date;
    duration: number;
    endDate: Date;
    goTo: (percent: number) => void;
    isActive: boolean;
    isAnimating: boolean;
    off: (event: string, callback?: (data: any) => void) => void;
    on: (event: string, callback: (data: any) => void) => void;
    pause: () => void;
    play: () => void;
    resume: () => void;
    /**
     * @deprecated reset appears to not work the way you'd think in 1.4.3. Probably use .play() then .pause()
     */
    reset: () => void;
    setEndDateUsingRelativeTime: (relativeDate: string, relativeTo: Date) => void;
    startDate: Date;
    state: string;
  };
}

export interface AerisUnits {
  CtoF: (num: number) => number;
  FtoC: (num: number) => number;
}

export interface BoatLanding {
  AccessName: string;
  County: string;
  DataSource: string;
  HUC12: string;
  HUC12_Name: string;
  Latitude: number;
  Longitude: number;
  State: string;
  Type: 'Public' | 'Semi-Private';
}

export interface WaterbodyOutline {
  waterbody_id: string;
}

export interface ExtendedGeoJsonFeature extends mapboxgl.MapboxGeoJSONFeature {
  properties: {
    val: number;
  };
}

export const isBoatLandingFeature = (
  dataPointProperties: BoatLanding | Record<string, any>
): dataPointProperties is BoatLanding => {
  if ('AccessName' in dataPointProperties) {
    return true;
  }
  return false;
};

export const isInteractiveWaterbodyFeature = (
  dataPointProperties: WaterbodyOutline | Record<string, any>
): dataPointProperties is WaterbodyOutline => {
  if ('waterbody_id' in dataPointProperties) {
    return true;
  }
  return false;
};

export const isWaterTempFeature = (
  feature: mapboxgl.MapboxGeoJSONFeature
): feature is ExtendedGeoJsonFeature => {
  if (feature.source === 'omnia_water_temp') {
    return true;
  }
  return false;
};

export const isWaterClarityFeature = (
  feature: mapboxgl.MapboxGeoJSONFeature
): feature is ExtendedGeoJsonFeature => {
  if (feature.source === 'omnia_clarity') {
    return true;
  }
  return false;
};
